/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import ResultsInfo from '../../Info/ResultsInfo';
import HandleScroll from './HandleScroll';
import ProductCard from '../CardLayout/ProductCard';
const FilterServices = require('../../../Services/Filters/Actions');
const LanguageServices = require('../../../Services/Languages/Actions');

const Get_Catalogues = gql`query catalogue($cat: ID!,$limit: Int, $searchTerm: [String] $filterTerm: [FilterClause!], $token: String!, $customer: String) {
                                  catalogue(name: $cat) {
                                    name
                                    products(limit: $limit, searchTerm: $searchTerm, filter: $filterTerm, token: $token,customer: $customer) {
                                      items {
                                        productCode
                                        description
                                        longDescription
                                        price
                                        currency
                                        per
                                        uom
                                        orderQty
                                        roundingQty
                                        minOrderQty
                                        leadtime
                                        UNSPSC
                                        manufName
                                        manufCode
                                        image
                                        match
                                        dimensions{
                                            length
                                            width
                                            height  
                                            weight
                                        }
                                      }
                                     token
                                    }
                                  }
                                }`

export const PageGenerator = ({ ClearFilter, setClearFilter, CatConfig, classes, SessionId, isFilteredFavourite, basketCount, SetBasketCount, filters, categories, CatLang, Languages, SideFilter}) => {
    const defaultRowCount = 48;
    var [StoredToken, setStoredToken] = useState("");
    var [queryToken, setQueryToken] = useState("0");
    var [LoadedAll, setLoadedAll] = useState(false);
    var [AppliedFilter, setAppliedFilter] = useState(null);
    var [dataRows, setdataRows] = useState([]);
    var isFiltered = typeof filters != 'undefined' && filters != 0 && filters != "NaN" || SideFilter.length > 0;
    var matFilter = isFiltered ? filters : null;
    var favourites = FilterServices.GetFavourites();
    var op = (isFilteredFavourite && favourites.length > 0 || favourites != "NaN") ? "or" : "and";
    var filterCount = matFilter == null ? 0 + SideFilter.length : matFilter.length + SideFilter.length;
    const Scrolled = HandleScroll();

    const [LoadNextResult, { called, loading, data }] = useLazyQuery(
        Get_Catalogues,
        {
            variables: { limit: defaultRowCount, searchTerm: AppliedFilter, filterTerm: SideFilter, token: queryToken, cat: CatConfig.name,customer: localStorage.getItem("customer") },
            onCompleted: data => {
                increcment(data.catalogue.products.token, data.catalogue.products.items);
            }
        }
    );

    useEffect(() => {
        if ((Scrolled) && (parseInt(StoredToken) > parseInt(queryToken)) && !LoadedAll) {
            setQueryToken(StoredToken);
        } 
    }, [Scrolled])

    useEffect(() => {
        if (isFiltered) {
            setdataRows([])
            setLoadedAll(false)
            setAppliedFilter(filters)
            setQueryToken("0");
        }
    }, [isFiltered, filters, SideFilter])

    useEffect(() => {
        if (dataRows.length == 0 && !ClearFilter) {
            LoadNextResult()
        } else if (ClearFilter) {
            setClearFilter(false)
            setdataRows([])
            setAppliedFilter(null)
            setLoadedAll(false)
            setQueryToken("0");
        }
    }, [dataRows, ClearFilter])

    useEffect(() => {
        if (!isFilteredFavourite) {
            setdataRows([])
            setAppliedFilter(matFilter)
            setLoadedAll(false)
            setQueryToken("0");
        }
    }, [isFilteredFavourite])

 

    async function increcment (token, items) {
        setStoredToken(token);
        var ExistingRows = dataRows;
        ExistingRows = ExistingRows.concat(items)
        setdataRows(ExistingRows)
        if (items.length == 0 || items.length < defaultRowCount) await setLoadedAll(true)
    }
    if (isFilteredFavourite && SideFilter.length == 0) return (
        <div>
            <ResultsInfo CatConfig={CatConfig} Count={0} Filters={1} />
            <p style={{ padding: "15px", display: "block", margin: "0px" }}>{Languages.GetText(CatLang, "noProdsAddedToFavs")}</p>
        </div>    
        );        
            
                return (
                    <div style={{paddingTop: "20px"}}>
                        <ResultsInfo CatConfig={CatConfig} Count={dataRows.length} Filters={filterCount} CatLang={CatLang} Languages={Languages} />
            {
                            dataRows.map((result, index) => (
                                <div style={{ display: "inline-block", padding: "12px" }} key={index}>
                                    <ProductCard classes={classes} key="index" CatLang={CatLang} CatConfig={CatConfig} SessionId={SessionId} ProductInfo={result} filters={filters} rows={dataRows} index={index} favourites={favourites} basketCount={basketCount} SetBasketCount={SetBasketCount} categories={categories} CatLang={CatLang} Languages={Languages}/>
                    </div>
                ))
            }
            {
                ((loading || called) && !LoadedAll) && (
                <div style={{ display: "block", padding: "15px" }}>
                    <p>{LanguageServices.GetText(CatLang, "fetching")}</p>
                     <CircularProgress disableShrink />
                </div>
            )}
            {
                (LoadedAll) && (
                <div style={{ display: "block", padding: "15px" }}>
                     <p>{LanguageServices.GetText(CatLang, "noMoreResults")}</p>
                </div>
            )}
        </div>
    )
};
    
    
    
    
