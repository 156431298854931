import gql from 'graphql-tag';
const Audit = require('../../Services/Audit/Actions');

const query = gql` query getSession($catalogue: ID!, $sessionId: ID!) {
  session(catalogue: $catalogue, sessionId: $sessionId)
  {
    sessionId
    basket {
      orderQty
      product { 
		productCode
		description
		longDescription
		price
		currency
		per
		uom
		orderQty
		roundingQty
		minOrderQty
		leadtime
		UNSPSC
		manufName
		manufCode
		image }
    }
  }
}
`;

export const AddToFavourites = (CatLang, Languages, client, code, isFavourite, controlId, setStatus, CatConfig, setExpanded) => {
    var favourites = [];
    var storage = localStorage.getItem("SupplyCatFavourites");
    favourites = storage != null ? JSON.parse(storage).Favourites : [];
    var isFavourite = favourites.includes(code);
    if (!isFavourite) {
        favourites.push(code);
        localStorage.setItem("SupplyCatFavourites", JSON.stringify({ Favourites: favourites }));
        controlId.currentTarget.style.color = '#ffc107';
        setExpanded(true);
        Audit.Add(client, "USER ADDED PRODUCT TO FAVOURITES", code);
        setStatus(Languages.GetText(CatLang, "Producthasnowbeenaddedtoyourfavourites"), "success");
    } else {
        favourites.pop(code);
        localStorage.setItem("SupplyCatFavourites", JSON.stringify({ Favourites: favourites }));
        controlId.currentTarget.style.color = CatConfig.themeColour;
        setExpanded(false);
        Audit.Add(client, "USER REMOVED PRODUCT FROM FAVOURITES", code);
        setStatus(Languages.GetText(CatLang, "Producthasnowbeenremovedfromyourfavourites"), "success");
    }
}

export async function IncrementItem (CatLang, Languages, data, props, client, CatConfig, UpdateBasket, setStatus, qty) {
    var basketQuery = [];
    data.orderQty = parseInt(qty);
    if (qty < 1 || qty > 49999) { setStatus(Languages.GetText(CatLang, "Quantityhastobebetween"), "error"); return; }
    if (!isInt(qty)) { setStatus(Languages.GetText(CatLang, "Quantitymustbeawholenumber"), "error"); return; }
    var lineExist = false
    await client.query({ query, variables: { catalogue: CatConfig.name, sessionId: props.SessionId } }).then(async (result) => {
        if (result.data.session != null) {
            for (var i = 0; i <= result.data.session.basket.length - 1; i++) {
                if ((result.data.session.basket[i].product.productCode == data.productCode)) {
                    result.data.session.basket[i].orderQty = Number(result.data.session.basket[i].orderQty) + Number(data.orderQty);
                    lineExist = true;
                }
                basketQuery.push({ orderQty: result.data.session.basket[i].orderQty, productCode: result.data.session.basket[i].product.productCode });
            }
        }
        if (!lineExist) basketQuery.push({ orderQty: data.orderQty, productCode: data.productCode });
        props.SetBasketCount(basketQuery.length);
        await UpdateBasket({ variables: { catalogue: CatConfig.name, sessionId: props.SessionId, basketcontent: basketQuery } });
        setStatus(Languages.GetText(CatLang, "Producthasnowbeenaddedtobasket"), "success");
        Audit.Add(client, "USER ADDED ITEM TO BASKET", JSON.stringify(result));

    });

}

function isInt(value) {
    var x;
    return isNaN(value) && (value % 1 != 0) ? !1 : (x = parseFloat(value), (0 | x) === x);
}