const main = `<?xml version="1.0" encoding="UTF-8"?>
<cXML payloadID="%s">
   <Header>
      <From>
         <Credential domain="DUNS">
            <Identity>SupplyCAT</Identity>
         </Credential>
      </From>
      <To>
         <Credential domain="DUNS">
            <Identity>mymro.com</Identity>
         </Credential>
      </To>
      <Sender>
         <Credential domain="IESAnetworkUserID">
            <Identity>SupplyCAT</Identity>
            <SharedSecret></SharedSecret>
         </Credential>
         <UserAgent>SupplyCATv1.0</UserAgent>
      </Sender>
   </Header>
   <Message>
      <PunchOutOrderMessage>
         <BuyerCookie />
         <PunchOutOrderMessageHeader operationAllowed="create">
            <Total>
               <Money currency="GBP">%s</Money>
            </Total>
         </PunchOutOrderMessageHeader>
            %s
      </PunchOutOrderMessage>
   </Message>
</cXML>`;

export default main;