import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Drawer from '@material-ui/core/Drawer';
import { ChipFilter } from '../Filters/ChipSearch'
import { FaFilter } from 'react-icons/fa';
import Icon from '@material-ui/core/Icon';
import CardHeader from '@material-ui/core/CardHeader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { FilterClear } from '../Filters/ClearFilter';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';





export const SideDrawer = ({ Languages, CatLang, setResetSearch, QueryMat, SetQueryMat, SetFilteredFavourite, setClearFilter, classes, CatConfig, SearchProducts, filters, categories, Manfs, isFilteredFavourite, SideFilter }) => {
    const [open, setOpen] = React.useState(true);
    var ShowFavouritesOnly = Languages.GetText(CatLang, "ShowFavouritesOnly")
    var FiltersTextid = Languages.GetText(CatLang, "Filters")

    var fTypes = [
        ["Categories", "Categories", categories] ,
        ["manufName", "Manufacturer", Manfs] ,
    ];
    //debugger;
    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    var filterTextVisible = !open ? "none" : "inline";


    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
            open={open}
        >

            <div className={classes.filters}>
                
                <div className={classes.toolbar} style={{ marginTop: "15px" }}>
                    <Icon  color='primary' value={{ size: "1.5em" }}>
                        <FaFilter /> <CardHeader color="textSecondary"
                            className={classes.descriptionText}
                            style={{ display: filterTextVisible, marginLeft: "-5%" }}
                            titleTypographyProps={{ variant: "inherit" }}
                            title={FiltersTextid}
                        />
                    </Icon>
                </div>
                <Divider />
                <div style={{ display: filterTextVisible }} >
                    {fTypes.map((item, i) => {
                        return (
                            <div className={classes.toolbar} key={i} >
                                <ChipFilter key={i} Languages={Languages} CatLang={CatLang} QueryMat={QueryMat} SetQueryMat={SetQueryMat} SetFilteredFavourite={SetFilteredFavourite} filters={filters} id={item[0]} CatConfig={CatConfig} SearchProducts={SearchProducts} setClearFilter={setClearFilter} FilterClear={FilterClear} field={item[0]} Text={item[1]} value={item[2]} />
                            </div>
                        )
                    })
                    }
                <div className={classes.SwitchAvatar}>
                        <FormControlLabel id="sFavourites" style={{ color: "rgba(0, 0, 0, 0.54)" }}
                            control={
                                <Switch checked={isFilteredFavourite} onChange={async (event, checked) => {
                                    var Selected = checked ? "ShowFavourites" : "HideFavourites";
                                    SetFilteredFavourite(Selected == "ShowFavourites")
                                    SearchProducts(event, "", Selected);
                                }} />
                            }
                        />
                    </div>
                    <p style={{ paddingBottom: "30px", color: "rgba(0, 0, 0, 0.54)", fontSize: "95%", margin: 0, maxHeight: "28px", width: "100%", maxWidth: "100%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", display: "inline-block" }}>{ShowFavouritesOnly}</p>
                    </div>
                
                <Divider />
                <FilterClear CatLang={CatLang} Languages={Languages} CatConfig={CatConfig} SearchProducts={SearchProducts} filters={filters} SideFilter={SideFilter} DrawerOpen={open} setClearFilter={setClearFilter} SetFilteredFavourite={SetFilteredFavourite} />  
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerToggle}>
                        {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
            </div>
        </Drawer>
    );
}


