import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 10,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
    },
    image: {
        width: 80,
        height: 80,
    },
    img: {
        margin: 'auto',
        display: 'block',
        width: 80,
        height: 80,
        cursor: "pointer"
    },
    textField: {
        width: 70,
    },
}));

export const ContactsCard = ({ item,SupplierLogo, CatLang, Languages }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item>
                        <div className={classes.image}>
                            <img className={classes.img} alt="complex" src={SupplierLogo} onClick={async (event) => { window.open(SupplierLogo, '_blank') }} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <div>
                                <Grid item xs style={{ padding: 20 }}>
                                    <Typography variant="body2" color="textSecondary">
                                        {Languages.GetText(CatLang, "Department")} : {item.department}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {Languages.GetText(CatLang, "Email")} : <a href={`mailto:${item.email}`} >{item.email}</a>
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {Languages.GetText(CatLang, "PhoneNumber")} : {item.phoneNumber}
                                    </Typography>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}