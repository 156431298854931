import React, { useEffect, useRef  } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InfoButton from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
    root: {
        margin: '4px 2px 4px 2px',
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '40%',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    toolTip: {
        fontSize: "13px"
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

export const SearchBar = ({ SetFilter, CatLang, Languages, ResetSearch, setResetSearch, SearchProducts, ClearFilter, CatConfig, setClearFilter, isFilteredFavourite, SetFilteredFavourite, onsubmit}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchText, setSearchText] = React.useState("");

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const onKeyPress = (e) => {
        if (e.which === 13) {
            if (!searchText == "") {
                setSearchText(e.target._valueTracker.getValue())
                SearchProducts(e, e.target._valueTracker.getValue(), "SearchBar");
                onsubmit(e.target._valueTracker.getValue);
            } else {
                SetFilter(null);
            }
            e.preventDefault();
        }
    }

    function handleInputChange(e) {
        setSearchText(e.target._valueTracker.getValue)
    }

    useEffect(() => {
        if (ClearFilter) {
            setSearchText("")
        }
    }, [ClearFilter])

    useEffect(() => {
        if (ResetSearch) {
            setSearchText("");
            setResetSearch(false);
        }
    }, [ResetSearch])

    return (
        <Paper component="form" className={classes.root}>
            <InputBase
                id="txtSearch"
                onKeyPress={onKeyPress}
                className={classes.input}
                placeholder={Languages.GetText(CatLang, "SearchCatalogue")}
                onChange={async (e) => handleInputChange(e)}
                inputProps={{ 'aria-label': 'Search Catalogue' }}
                onBlur={async (e) => { setSearchText(e.target._valueTracker.getValue()); onsubmit(e.target._valueTracker.getValue); }}
                value={searchText}


            />
            <IconButton className={classes.iconButton} aria-label="search" onClick={async (event) => {
                if (!searchText == "") {
                    SearchProducts(event, searchText, "SearchBar")
                }
            }}>
                <SearchIcon />
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
            <Tooltip title={<span className={classes.toolTip}>{Languages.GetText(CatLang, "YoucansearchbyProduct")}</span>}>
                <div>
                    <IconButton color="primary" className={classes.iconButton} aria-label="directions" onClick={handleClick} onKeyDown={handleClick} >
                        <InfoButton style={{ color: CatConfig.themeColour }} />
                    </IconButton>
                </div>
            </Tooltip>
        </Paper>
    );
}