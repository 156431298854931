import gql from 'graphql-tag';

const qString = require('query-string');
const mutation = gql`mutation audit($catalogue:ID!,$sessionId:ID!,$action:String,$payload:String) {
                          audit(catalogue:$catalogue sessionId:$sessionId action:$action,payload:$payload)
                        }
`;

export const Add = (client,act, pl) => {
    let params = qString.parse(window.location.search);
    var cSessionId = localStorage.getItem("payloadId");
    var cat = params.cat;
    client.mutate({ mutation: mutation, variables: { catalogue: cat, sessionId: cSessionId,action:act,payload:pl} });
}