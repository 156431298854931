import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import gql from 'graphql-tag';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import SupplyCATAlert from '../Alerts/Snackbar';
import Button from '@material-ui/core/Button';

const Services = require('../../Services/Basket/Actions');




const UpdateQuery = gql` mutation setSession($catalogue: ID!, $sessionId: ID!, $basketcontent: [InputBasketItem]) {
  updateSession(catalogue: $catalogue, sessionId: $sessionId, basket: $basketcontent) {
    sessionId
    basket{ orderQty, product { productCode
		description
		longDescription
		price
		currency
		per
		uom
		orderQty
		roundingQty
		minOrderQty
		leadtime
		UNSPSC
		manufName
		manufCode
		image }}
  }
}
`;

export const BasketCard = ({ Languages, CatLang, item, onSubmit, RefreshBasket, CatConfig, SessionId, SetBasketCount, SetBasketAddDisabled }) => {
    const client = useApolloClient();
    const [Quantity, setQuantity] = React.useState(item.orderQty);
    const [UpdateBasket, { updatedData }] = useMutation(UpdateQuery, {
        onCompleted: updatedData => {
            RefreshBasket(updatedData.updateSession.basket);
        }
    });
    var productCode = item.product.productCode
    let Price = item.product.price / item.product.per;
    Price = parseFloat(Price).toFixed(2);
    Price = new Intl.NumberFormat(CatLang.culture, { minimumFractionDigits: 2 }).format(Price);

    const useStyles = makeStyles(theme => ({
        root: {
            marginBottom: 10,
        },
        paper: {
            padding: theme.spacing(2),
            margin: 'auto',
            maxWidth: 500,
        },
        image: {
            width: 128,
            height: 128,
        },
        img: {
            margin: 'auto',
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
            cursor: "pointer"
        },
        textField: {
            width: 70,
            '& label.Mui-focused': {
                color: CatConfig.themeColour
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: CatConfig.themeColour
            },
        },
    }));
    const classes = useStyles();

   
    const [status, setStatusBase] = React.useState("");
    const setStatus = (msg, type) => setStatusBase({ msg, type });

    const QuantityKeydown = (keydown, NewQty, productCode, e, client, CatConfig, UpdateBasket, setStatus, SessionId, onSubmit, Languages, CatLang, moq, rnd) => {
        if (keydown == 13 || parseInt(e.target.value) < 1) {
            e.preventDefault();
            if (parseInt(NewQty)) {
                var m = (parseInt(e.target.value) % rnd);
                if (m != 0) {
                    SetBasketAddDisabled(true);
                    setStatus(`${Languages.GetText(CatLang, "QuantityMustBeDivisibleBy")} ${rnd}`, "error");
                } else if (parseInt(NewQty) >= moq) {
                    SetBasketAddDisabled(false);
                    Services.IncrementItem(Math.round(NewQty), productCode, e, client, CatConfig, UpdateBasket, setStatus, SessionId, onSubmit, Languages, CatLang);
                    setQuantity(Math.round(NewQty))
                    e.target.value = Math.round(NewQty)
                }
                else {
                    SetBasketAddDisabled(true);
                    setStatus(`${Languages.GetText(CatLang, "QuantityMustBeGreaterThan")} ${moq}`, "error");
                }

            }
        }
    }

    function onChange(e, moq, rnd, SetBasketAddDisabled, setStatus, Languages) {
        if (parseInt(e.target.value) || parseInt(e.target.value) < 1) {
            var m = (parseInt(e.target.value) % rnd);
            if (m != 0) {
                SetBasketAddDisabled(true);
                setStatus(`${Languages.GetText(CatLang, "QuantityMustBeDivisibleBy")} ${rnd}`, "error");
            } else if (parseInt(e.target.value) >= moq) {
                SetBasketAddDisabled(false);
                Services.IncrementItem(Math.round(e.target.value), productCode, e, client, CatConfig, UpdateBasket, setStatus, SessionId, onSubmit, Languages, CatLang)
                e.target.value = Math.round(e.target.value)
            } else {
                SetBasketAddDisabled(true);
                setStatus(`${Languages.GetText(CatLang, "QuantityMustBeGreaterThan")} ${moq}`, "error");
            }
        }
        else {
            e.target.value = item.orderQty
        }
    }

    function validate(e, moq, rnd, SetBasketAddDisabled, setStatus, Languages) {
        if (parseInt(e.target.value) < 1) {
            var m = (parseInt(e.target.value) % rnd);
            if (m != 0) {
                SetBasketAddDisabled(true);
                setStatus(`${Languages.GetText(CatLang, "QuantityMustBeDivisibleBy")} ${rnd}`, "error");
            } else if (parseInt(e.target.value) >= moq) {
                Services.IncrementItem(Math.round(e.target.value), productCode, e, client, CatConfig, UpdateBasket, setStatus, SessionId, onSubmit, Languages, CatLang)
                e.target.value = Math.round(e.target.value)
            } else {
                SetBasketAddDisabled(true);
                setStatus(`${Languages.GetText(CatLang, "QuantityMustBeGreaterThan")} ${moq}`, "error");
            }
            e.target.value = item.orderQty
        }
    }


    return (
        <div className={classes.root}>
            {
                (status != "") && (<SupplyCATAlert setStatusBase={setStatusBase} key={status.date} status={status.msg} type={status.type} />)
            }
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item>
                        <div className={classes.image}>
                            <img className={classes.img} alt="complex" src={item.product.image} onClick={async (event) => { window.open(item.product.image, '_blank') }} onError={(e) => { e.target.onerror = null; e.target.src = "http://supplycat.mymro.com/catimg/image-unavailable.jpg" }} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <div>
                                
                                <Grid item xs style={{padding: 8}}>
                                    <Typography gutterBottom variant="subtitle1" style={{ fontWeight: 'bold' }} color="textSecondary">
                                        {item.product.description}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <TextField
                                            type="number"
                                            label={Languages.GetText(CatLang, "Quantity")}
                                            id={`txtQty${item.orderQty}`}
                                            InputProps={{ inputProps: { min: item.product.minOrderQty, step: item.product.roundingQty } }}
                                            onChange={e => { validate(e, item.product.minOrderQty, item.product.roundingQty, SetBasketAddDisabled, setStatus, Languages) }}
                                            className={classes.textField} defaultValue={Quantity}
                                            onBlur={(e) => { e.persist(); onChange(e, item.product.minOrderQty, item.product.roundingQty, SetBasketAddDisabled, setStatus, Languages) }}
                                            onKeyDown={(e) => { e.persist(); QuantityKeydown(e.keyCode, e.target.value, productCode, e, client, CatConfig, UpdateBasket, setStatus, SessionId, onSubmit,Languages,CatLang, item.product.minOrderQty, item.product.roundingQty) }}
                                        />
                                        
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {Languages.GetText(CatLang, "Code")}: {productCode}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {Languages.GetText(CatLang, "manufName")}: {item.product.manufName}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {Languages.GetText(CatLang, "manufCode")}: {item.product.manufCode}
                                    </Typography>

                                </Grid>
                            </div>
                            <div>
                                <Grid item>
                                    <Typography variant="body2" style={{ cursor: 'pointer' }}>
                                        <Button onClick={async (event) => { Services.RemoveBasketItem(client, productCode, CatConfig, UpdateBasket, SessionId, onSubmit, SetBasketCount) }} style={{ color: "red" }}  >{Languages.GetText(CatLang, "Delete")}</Button>
                                 </Typography>
                                </Grid>
                            </div>
                        </Grid>
                        <div>
                            <Grid item>
                                <Typography color="textSecondary" variant="subtitle1">{Price} {item.product.currency}</Typography>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}