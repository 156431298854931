import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const qString = require('query-string');
const Services = require('../../Services/Basket/Actions')
const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    },
    text: {
      margin: "-13%",
      marginTop: "1%"
    }
}));

export default function SimpleBackdrop(props) {
    const classes = useStyles();
    //debugger;
    let params = qString.parse(window.location.search);
    if(props.type == "ReturnEmpty")
        Services.ReturnEmptyCart();
    if (props.type == "ReturnBasket")
        Services.HandleRequisitionReturn(props.client, 0, params.cat, params.sessionId,props.carriageOptions)
    if (props.open == "true") {

        return (
            <div>
                <Backdrop className={classes.backdrop} open={props.open}>
                    <CircularProgress color="inherit" />
                    <div className={classes.text}> {props.message} </div>
                </Backdrop>
            </div>
        );
    }
   
    return "";
}
