import React from 'react';
import { Main } from './components/Main';
import './App.css';
import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from '@apollo/react-hooks';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import PunchoutError from './components/Error/PunchoutError';
import ProgressIndicator from './components/ProgressBar/ProgressBarMain';
import InternalError from './components/Error/InternalError';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';

const { useState } = React;
const qString = require('query-string');
const FilterServices = require('./Services/Filters/Actions');
const CatalogueServices = require('./Services/CatalogueConfig/Actions');
const TokenServices = require('./Services/Tokens/Actions');
const ConfigServices = require('./Services/Config/Actions');
const Config = require('./Config.json');




const link = new HttpLink({
    uri: ConfigServices.GetConfigSetting(Config, "GraphQLEndPoint"),
    fetch: TokenServices.awsGraphqlFetch
});

const cache = new InMemoryCache();

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    mutate: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
};

const apolloclient = new ApolloClient({
    link: link,
    cache: cache,
    defaultOptions: defaultOptions
});





export default function App() {
    var [props, SetFilter] = useState(null);
    var [SideFilter, SetSideFilter] = useState([]);
    var [catConfig, SetCatConfig] = useState(0);
    var [catLang, setCatLang] = useState(0);
    var [isQueried, SetQueried] = useState(false);
    var [isProgressing, SetIsProgressing] = useState([false,""]);
    var [isFilteredFavourite, SetFilteredFavourite] = useState(false);
    var [searchBar, SetSearchBar] = useState("");
    var [categories, setCategories] = useState([]);
    var [Manfs, SetManfs] = useState([]);
    var [isValidSession, SetValidSession] = useState(true);
    var [FavouriteIsSelected, SetFavouriteIsSelected] = useState(false);
    var [QueryMat, SetQueryMat] = useState(false);
    var [ClearFilter, setClearFilter] = useState(false);
    let params = qString.parse(window.location.search);
    const [searchText, setSearchText] = React.useState("");  


    if (typeof params.sessionId != "undefined" && isValidSession && !isProgressing[0]) {
        CatalogueServices.SetCatalogueConfig(params.cat, catConfig, SetCatConfig, apolloclient, SetValidSession);
    }

    var theme = 
         createMuiTheme({
            palette: {
                primary: {
                    main: (!catConfig == 0) ? catConfig.themeColour : "#888",
                },
                secondary: {
                    main: (!catConfig == 0) ? catConfig.themeColour : "#888",
                },
            },
        });
    const SearchProducts = (e, property, id) =>
        FilterServices.SearchProducts(catConfig.name,apolloclient,e, property, id, props, SetFilter, categories, setCategories,
            Manfs, SetManfs, SetSearchBar, SetFavouriteIsSelected, SetQueried, SideFilter, SetSideFilter, setClearFilter);

    if (typeof params.sessionId != "undefined" && localStorage.getItem("rToken") == null && isValidSession && !isProgressing[0])
        TokenServices.SetRefreshToken(apolloclient, params.cat);

    React.useEffect(() => {
        if (typeof params.mat != "undefined") {
            SetSideFilter([{ attribute: "productCode", filterTerm: [params.mat] }]);
            SetQueryMat(true)
        }
    }, [params.mat])


    if (typeof params.sessionId == "undefined")
        return (<div><PunchoutError /></div>);
    else if (!isValidSession || TokenServices.IsTokenExpired().valueOf())
        return (<div><InternalError /></div>);
    else if (catConfig != 0) {
        return (
            <MuiThemeProvider theme={theme}>
            <div>
                    <ApolloProvider client={apolloclient}>
                        {isProgressing[0] ? <ProgressIndicator open="true" client={apolloclient} type={isProgressing[1]} message={isProgressing[2]} carriageOptions={catConfig.carriageOptions} /> : <div className="App" style={{ height: "100%", minHeight: "98vh" }}>
                            <Main SearchProducts={SearchProducts}
                                categories={categories}
                                Manfs={Manfs}
                                searchBar={searchBar}
                                FavouriteIsSelected={FavouriteIsSelected}
                                filters={props}
                                SideFilter={SideFilter}
                                CatConfig={catConfig}
                                CatLang={catLang}
                                SessionId={params.sessionId}
                                IsQueried={isQueried}
                                SetQueried={SetQueried}
                                SetFilter={SetFilter}
                                SetSideFilter={SetSideFilter}
                                isFilteredFavourite={isFilteredFavourite}
                                SetIsProgressing={SetIsProgressing}
                                SetFilteredFavourite={SetFilteredFavourite}
                                QueryMat={QueryMat}
                                SetQueryMat={SetQueryMat}
                                ClearFilter={ClearFilter}
                                setSearchText={setSearchText}
                                setClearFilter={setClearFilter}
                                setCatLang={setCatLang}
                                CatLang={catLang}
                        />
                     
                    </div>
                    }
                </ApolloProvider>
                </div>
            </MuiThemeProvider>
        );
    }
    else {
        return <p/>
    }


}
