import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent';

const useStyles = makeStyles(theme => ({
    root: {
        margin: "auto",
        textAlign:"center",
        marginBottom: "10px",
        maxWidth: "95%",
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    header: {
        height: '100vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    }
}));


export default function LongTextSnackbar(props) {
    var translateId = ""
    const classes = useStyles();
    var resultCount = props.Count;
    var filterCount = props.Filters;


    String.prototype.format = function (placeholders) {
        var s = this;
        for (var propertyName in placeholders) {
            var re = new RegExp('{' + propertyName + '}', 'gm');
            s = s.replace(re, placeholders[propertyName]);
        }
        return s;
    };

    if (props.CatLang != 0) {
        if (typeof(props.CatLang) != "undefined") {
            var translateId = props.Languages.GetText(props.CatLang, "ResultsDisplayed")
            var message = translateId.format({ Param1: resultCount, Param2: filterCount })
            if (resultCount == 0)
                message = props.Languages.GetText(props.CatLang, "NoResultsFound");
            if (filterCount > 0 && resultCount > 0) {
                var msgtranslateId = props.Languages.GetText(props.CatLang, "FilterResults")
                message = msgtranslateId.format({ Param1: resultCount, Param2: filterCount })
            }
        }
    }
    return (
        <div className={classes.root}>
            <SnackbarContent style={{
                height: '100%',
                backgroundColor: props.CatConfig.resultsInfoColour,
                color: '#0D0D0D',
                boxShadow: "0px 0px 0px",
                border: `1px solid ${props.CatConfig.resultsInfoColour}`}}
                  message={
                      <div style={{ margin: "auto", color:"rgba(0, 0, 0, 0.54)" }}> {message}</div>
                }
            />
            </div>
    );
}
