import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import { BtnAddBasket } from '../../Basket/BasketButton';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import InfoButton from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import SupplyCATAlert from '../../Alerts/Snackbar';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import StarIcon from '@material-ui/icons/Star';
import { ProductInfo } from '../CardLayout/MoreProductInfo';

const Services = require('../../../Services/ProductCard/Actions');
const Audit = require('../../../Services/Audit/Actions');
const FilterServices = require('../../../Services/Filters/Actions');

const UpdateQuery = gql` mutation setSession($catalogue: ID!, $sessionId: ID!, $basketcontent: [InputBasketItem]) {
  updateSession(catalogue: $catalogue, sessionId: $sessionId, basket: $basketcontent) {
    sessionId
    basket { product { productCode }}
  }
}
`;

export default function ProductCard(props) {
    const client = useApolloClient();
    const [expanded, setExpanded] = React.useState(false);
    const [status, setStatusBase] = React.useState("");
    const [Quantity, setQuantity] = React.useState(props.ProductInfo.minOrderQty);
    const [basketAddDisabled, setBasketAddDisabled] = React.useState(false);
    const setStatus = (msg, type) => setStatusBase({ msg, type });
    var favourites = FilterServices.GetFavourites();
    var CatConfig = props.CatConfig;
    var matchTextclass
    var matchCardclass
    var descriptionClass

    const [UpdateBasket, { updatedData }] = useMutation(UpdateQuery);

    var today = new Date();
    var expectedDate = new Date();
    expectedDate.setDate(today.getDate() + parseInt(props.ProductInfo.leadtime));

    var isFavourite = favourites.includes(props.ProductInfo.productCode);

    function onChange(e,moq,rnd) {
        if (parseInt(e.target.value)) {
            var m = (parseInt(e.target.value) % rnd);
            //debugger;
             if (m != 0) {
                 setStatus(`${props.Languages.GetText(props.CatLang, "QuantityMustBeDivisibleBy")} ${rnd}`, "error");
                setBasketAddDisabled(true);
             } else if (parseInt(e.target.value) >= moq)
                setBasketAddDisabled(false);
            else {
                 setStatus(`${props.Languages.GetText(props.CatLang, "QuantityMustBeGreaterThan")} ${moq}`, "error");
                setBasketAddDisabled(true);
            }
            setQuantity(Math.round(e.target.value));
        }
    }
    let price = parseFloat(props.ProductInfo.price).toFixed(2) / parseInt(props.ProductInfo.per);
    price = new Intl.NumberFormat(props.CatLang.culture, { minimumFractionDigits: 2 }).format(price);

    let productCodeFontSize = "95%"; 
    if (props.ProductInfo.productCode.length > 15)
        productCodeFontSize = "75%";
    if (props.ProductInfo.productCode.length > 25)
        productCodeFontSize = "55%";
    if (props.ProductInfo.productCode.length > 35)
        productCodeFontSize = "45%";

    let descriptionFontSize = "100%";
    if (props.ProductInfo.description.length > 100)
        descriptionFontSize = "75%";
    if (props.ProductInfo.description.length > 150)
        descriptionFontSize = "55%";
    if (props.ProductInfo.description.length > 175)
        descriptionFontSize = "45%";

    if (props.ProductInfo.match == "EXACT MATCH") {
        matchTextclass = props.classes.descriptionTextExactMatch
        matchCardclass = props.classes.cardExact
        descriptionClass = props.classes.descriptionTextnmt
    } else if (props.ProductInfo.match == "BEST MATCH") {
        matchTextclass = props.classes.descriptionTextBestMatch
        matchCardclass = props.classes.cardBest
        descriptionClass = props.classes.descriptionTextnmt
    } else {
        matchTextclass = props.classes.descriptionNoMatch
        matchCardclass = props.classes.card
        descriptionClass = props.classes.descriptionText
    }

    function isInt(value) {
        var x;
        //debugger;
        return isNaN(value) && (value % 1 != 0) ? !1 : (x = parseFloat(value), (0 | x) === x);
    }
    //debugger;
    return (
        <Card className={matchCardclass}>
            <CardHeader color="textSecondary"
                className={matchTextclass}
                titleTypographyProps={{ variant: "inherit" }}
                style={{ padding: "0px" }}
                title={props.Languages.GetText(props.CatLang, props.ProductInfo.match)}
            />
            <CardHeader color="textSecondary"
                className={descriptionClass} style={{ fontSize: descriptionFontSize}}
                titleTypographyProps={{ variant: "inherit" }}
                title={props.ProductInfo.description}
            />
            <Tooltip title={<span className={props.classes.toolTip}>{props.ProductInfo.longDescription}</span>} placement="top">
                <div style={{ display: 'inline-block' }}>
                    <IconButton color="primary" className={props.classes.iconButton} aria-label="directions">
                        <InfoButton style={{ color: CatConfig.themeColour }} />
                    </IconButton>
                </div>
            </Tooltip>


            <Tooltip title={<span className={props.classes.toolTip}> {props.Languages.GetText(props.CatLang, "expectedDelivery")} {
                new Intl.DateTimeFormat(props.CatLang.culture, {
                    year: "numeric",
                    month: "long",
                    day: "2-digit"
                }).format(expectedDate)} {props.Languages.GetText(props.CatLang, "ifBuyToday")}</span>} placement="top" >
                <div style={{ display: 'inline-block' }}>
                    <IconButton color="primary" className={props.classes.iconButton} aria-label="directions">
                        <WatchLaterIcon style={{ color: CatConfig.themeColour }} />
                    </IconButton>
                </div>
            </Tooltip>
            <Tooltip title={<span className={props.classes.toolTip}>{isFavourite ? props.Languages.GetText(props.CatLang,"isInFavorites")  :  props.Languages.GetText(props.CatLang,"addToFavorites")} </span>} placement="top" >
                <div style={{ display: 'inline-block' }}>
                    <IconButton color="primary" className={props.classes.iconButton} aria-label="directions" onClick={async (event) => { Services.AddToFavourites(props.CatLang, props.Languages, client, props.ProductInfo.productCode, isFavourite, event, setStatus, CatConfig,setExpanded) }}>
                        <StarIcon id={`ib${props.index}`} style={{ color: isFavourite ? '#ffc107' : CatConfig.themeColour }} />
                    </IconButton>
                </div>
            </Tooltip>
            <img className={props.classes.img} alt="complex" src={props.ProductInfo.image} onError={(e) => { e.target.onerror = null; e.target.src = "http://supplycat.mymro.com/catimg/image-unavailable.jpg" }}  onClick={async (event) => { window.open(props.ProductInfo.image, '_blank'); }} />
            <CardContent>
                <div style={{ width: "100%", overflow: "hidden" }}>
                    <p style={{ fontSize: productCodeFontSize, margin: 0, maxHeight: "28px", width: "100%", maxWidth: "100%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", display: "inline-block" }} title={props.ProductInfo.productCode}> {props.Languages.GetText(props.CatLang,"code")} : {props.ProductInfo.productCode}</p>
                </div> 
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }} color="textSecondary" component="p">
                    <p style={{ fontSize: "95%", margin: 0, maxHeight: "28px", width: "100%", maxWidth: "100%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", display: "inline-block" }} title={price + props.ProductInfo.currency}> {props.Languages.GetText(props.CatLang,"price")} : {price} {props.ProductInfo.currency} </p>
                </Typography>
            </CardContent>
            <CardActions>
                <ProductInfo ProductInfo={props.ProductInfo} classes={props.classes} categories={props.categories} CatLang={props.CatLang} Languages={props.Languages} dimensions={props.dimensions} />               

                <TextField
                    type="number"
                    label={props.Languages.GetText(props.CatLang,"quantity")}
                    style={{ marginLeft: "10%" }}
                    id={`txtQty${props.index}`}
                    onChange={e => { onChange(e, props.ProductInfo.minOrderQty, props.ProductInfo.roundingQty) }}
                    onBlur={e => { onChange(e, props.ProductInfo.minOrderQty, props.ProductInfo.roundingQty) }}
                    value={Quantity}
                    InputProps={{ inputProps: { min: props.ProductInfo.minOrderQty, step: props.ProductInfo.roundingQty } }}
                    className={props.classes.textField} defaultValue={props.ProductInfo.minOrderQty}
                />

                <Fab size="small" color="primary" style={{ background: CatConfig.themeColour, marginLeft: "10%" }} disabled={basketAddDisabled} aria-label="add" className={props.classes.margin} onClick={(event) => Services.IncrementItem(props.CatLang, props.Languages, props.ProductInfo, props, client, CatConfig, UpdateBasket, setStatus, event.currentTarget.parentElement.childNodes[1].childNodes[1].childNodes[0]._valueTracker.getValue())}>
                    <BtnAddBasket setStatus={setStatus} />
                </Fab>

                {status ? <SupplyCATAlert setStatusBase={setStatusBase} key={status.date} status={status.msg} type={status.type} /> : null}
            </CardActions>
        </Card>
    );
}
