import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';

import { NavBar } from './NavBar/NavBar';
import clsx from 'clsx';
import { ProductGrid } from '../components/Product/CardLayout/ItemGrid';
import { SideDrawer } from './SideDrawer/SideDrawer';
import '../App.css';
const { useState } = React;

const Languages = require('../Services/Languages/Actions');

const languageQuery = gql`query Region($language:ID!){
  locale(language:$language){
    language
    culture
    localTexts {
      localLabel
      localText
    }
  }
}`

export const Main = ({ CatLang, setCatLang, ClearFilter, setClearFilter, QueryMat, SetQueryMat, SetFilteredFavourite, SearchProducts, filters, props, SetFilter, IsQueried, SetQueried, CatConfig, SessionId, isFilteredFavourite, categories, Manfs, searchBar, FavouriteIsSelected, SetIsProgressing, setSearchText, SideFilter}) => {
    const SideDrawerStyles = require("../Services/Content/ComponentStyles").ExtractMainStyles(CatConfig.themeColour);
    const useStyles = SideDrawerStyles;
    const classes = useStyles();
    var [basketCount, SetBasketCount] = useState(0);
    var [ResetSearch, setResetSearch] = useState(false);
    //debugger;
    const [LoadLang, { called, loading, data }] = useLazyQuery(languageQuery, {
        variables: { language: CatConfig.language },
        onCompleted: data => {  setCatLang(data.locale) }
    });

    React.useEffect(() => {
        if (CatConfig != 0) LoadLang();
    }, [CatConfig])



    return (
        <div className={classes.root}>
            <NavBar CatLang={CatLang} Languages={Languages} SearchProducts={SearchProducts} filters={props}
                CatConfig={CatConfig} SessionId={SessionId}
                IsQueried={IsQueried} searchBar={searchBar} ResetSearch={ResetSearch} setResetSearch={setResetSearch} setSearchText={setSearchText}
                SetFilter={SetFilter}
                basketCount={basketCount} SetBasketCount={SetBasketCount} SetIsProgressing={SetIsProgressing} isFilteredFavourite={isFilteredFavourite} SetFilteredFavourite={SetFilteredFavourite} ClearFilter={ClearFilter} setClearFilter={setClearFilter}/>
            <SideDrawer classes={classes} CatConfig={CatConfig} Languages={Languages}
                SearchProducts={SearchProducts} filters={filters}
                SideFilter={SideFilter}
                SetFilter={SetFilter}
                categories={categories}
                Manfs={Manfs}
                SetFilteredFavourite={SetFilteredFavourite}
                isFilteredFavourite={isFilteredFavourite}
                setClearFilter={setClearFilter} ClearFilter={ClearFilter}
                SetQueryMat={SetQueryMat} QueryMat={QueryMat} filters={filters}
                setResetSearch={setResetSearch}
                CatLang={CatLang} />
            <main className={clsx(classes.content, { [classes.contentShift]: true, })}>
                <ProductGrid classes={classes} CatConfig={CatConfig}
                    filters={filters} IsQueried={IsQueried} setResetSearch={setResetSearch}
                    SearchProducts={SearchProducts} SetQueried={SetQueried}
                    setSearchText={setSearchText}
                    SessionId={SessionId} isFilteredFavourite={isFilteredFavourite} SetFilteredFavourite={SetFilteredFavourite}
                    basketCount={basketCount} SetBasketCount={SetBasketCount} ClearFilter={ClearFilter} setClearFilter={setClearFilter} CatLang={CatLang} Languages={Languages} SideFilter={SideFilter} />
            </main>
        </div>
    );
}