/* eslint-disable no-use-before-define */
import React from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import TextField from '@material-ui/core/TextField';
import InternalError from '../Error/InternalError';


export const ChipFilter = ({ Languages, CatLang, QueryMat, SetQueryMat, SetFilteredFavourite, filters, setClearFilter, SearchProducts, CatConfig, Text, field, value, AppliedModalFilters, setAppliedModalFilters}) => {
    const [queryable, setQueryable] = React.useState(false);
    const [distinctOptions, setDistinctOptions] = React.useState([]);
    var isFiltered = typeof filters != 'undefined' && filters != 0 && filters != "NaN";
    var matFilter = isFiltered ? filters : null;
    const useStyles = makeStyles(theme => ({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 400,
            marginLeft: "2px",

            '& label.Mui-focused': {
                color: CatConfig.themeColour
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: CatConfig.themeColour
            },
            '& .MuiChip-label': {
                maxWidth: "140px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "inline-block"

            },

            '& .MuiInputBase-input': {
                width: "0px",
                padding: "0px",
                minWidth: "0px"
            }
        },
        margin: {
            margin: theme.spacing(1),

        },
        chipFilter: {
            width: "80%",
            maxWidth: "80%",
            textOverflow: "ellipsis",
            marginLeft: "5%"
        },
    }));


    const classes = useStyles();



    var query = gql`query catalogue($cat: ID!, $searchTerm: [String!]){
                                  catalogue(name: $cat) {
                                  name
                                  products(searchTerm: $searchTerm) {
                                    items {
                                       ${field}
                                    }
                                  }
                                }
                            }`;
    if (field == "manufName")
        query = gql`query Manufacturers($cat: ID!){
                              catalogue(name: $cat){
                                manufacturers
                              }
                            }`

    if (field == "Categories")
         query = gql`query Categories{
                                      catalogue(name:"${CatConfig.name}"){
                                        categories
                                      }
                                    }`;

    const [LoadDetails, { called, loading, data }] = useLazyQuery(
        query, {
            variables: { cat: CatConfig.name, searchTerm: matFilter },
            
            onCompleted: data => {
                var distinctRows = [];
                const result = field != "manufName"? data.catalogue.categories : data.catalogue.manufacturers;
                distinctRows = result;
                console.log(distinctRows)
                setQueryable(false);
                setDistinctOptions(distinctRows);
            }
        }
    );

    React.useEffect(() => {

        if (QueryMat) {
            SearchProducts("", filters, "productCode")
        }
        else {
            SearchProducts("", "btnClearFilter");
            setClearFilter(true);
        }
    }, [QueryMat])

    return (

            <div className={classes.root}>
                <div style={{ width: 310 }}>
                <Autocomplete id={field} 
                    className={classes.chipFilter}
                    options={distinctOptions}
                    getOptionLabel={(option) =>  option = field != "manufName" ? option[1]: option }
                    multiple
                    value={value}
                    title={field}
                    noOptionsText={loading ? Languages.GetText(CatLang, "FetchingOptions") : Languages.GetText(CatLang, "NoOptionsAvailable") }
                    filterSelectedOptions
                    onOpen={async (event, value) => { LoadDetails() }}
                    onChange={async (event, value) => {
                            SearchProducts(event, value, field)
                    }} 
                renderInput={params => (
                    <TextField id={field}
                        {...params}
                        label={Languages.GetText(CatLang, field)}
                        value={field}
                        margin="normal"
                        fullWidth
                    />

                    )}

                    />
                    </div>
                </div>
    )
};



