import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

export const ProductInfo = (props) => {
    const [expanded, setExpanded] = React.useState('panelDimensions');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const classes = props.classes;
    const [open, setOpen] = React.useState(false);
    var categoryName =  props.Languages.GetText(props.CatLang,"notAvailable") ;
    const HandleClose = (setOpen) => {
        setOpen(false);
    };
    const handleExpandClick = (setOpen) => {
        setOpen(true);
    };
    if (open) {
        for (var i = 0; i < props.categories.length; i++) {
            if (props.categories[i][0] == props.ProductInfo.UNSPSC) {
                categoryName = props.categories[i][1];
                break;
            }
        }
    }

    if (!open)
        return (<Tooltip title={<span className={classes.toolTip}> {props.Languages.GetText(props.CatLang,"moreProdDetails")} </span>} placement="bottom" >
            <Button onClick={async (event) => { handleExpandClick(setOpen) }}><MoreVertIcon style={{ color: "rgba(0, 0, 0, 0.54)" }}></MoreVertIcon></Button>
        </Tooltip>)
    return (
        <div>
            <Tooltip title={<span className={classes.toolTip}>{props.Languages.GetText(props.CatLang,"moreProdDetails")}</span>} placement="bottom" >
                <Button onClick={async (event) => { handleExpandClick(setOpen) }}><MoreVertIcon style={{ color: "rgba(0, 0, 0, 0.54)" }}></MoreVertIcon></Button>
            </Tooltip>
            <Dialog disableEscapeKeyDown open={open} maxWidth="sm" fullWidth="true" onClose={async (event) => { HandleClose(setOpen) }}>
                <DialogTitle style={{ color: "rgba(0, 0, 0, 0.54)", fontWeight: "bold", paddingBottom: 0 }}>{props.Languages.GetText(props.CatLang, "ProductInfo")}</DialogTitle>
                <DialogContent>
                    <form className={classes.container}>
                        <div className={classes.MoreproductInfo}>
                                    <List dense="true">
                                        <ListItem>
                                            <ListItemText
                                                primary={props.Languages.GetText(props.CatLang,"detailedDescription")}
                                                secondary={props.ProductInfo.longDescription}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                primary={props.Languages.GetText(props.CatLang,"manufName")}
                                                secondary={props.ProductInfo.manufName}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                primary={props.Languages.GetText(props.CatLang,"manufCode")}
                                                secondary={props.ProductInfo.manufCode}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                primary={props.Languages.GetText(props.CatLang,"leadtime")}
                                                secondary={props.ProductInfo.leadtime}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                primary={props.Languages.GetText(props.CatLang,"uom")}
                                                secondary={props.ProductInfo.uom}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                primary={props.Languages.GetText(props.CatLang,"Category")}
                                                secondary={categoryName}
                                            />
                                        </ListItem>
                                    </List>
                                    {
                                (props.ProductInfo.dimensions != null && props.ProductInfo.dimensions.length != null && props.ProductInfo.dimensions.length != "0") && (
                                            <Accordion square onChange={handleChange('panelDimensions')}>
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                    <Typography>{props.Languages.GetText(props.CatLang, "Dimensions")}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <List dense="true">
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={props.Languages.GetText(props.CatLang, "Length")}
                                                                secondary={props.ProductInfo.dimensions.length + "cm"}
                                                            />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={props.Languages.GetText(props.CatLang, "Height")}
                                                                secondary={props.ProductInfo.dimensions.height + "cm"}
                                                            />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={props.Languages.GetText(props.CatLang, "Width")}
                                                                secondary={props.ProductInfo.dimensions.width + "cm"}
                                                            />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={props.Languages.GetText(props.CatLang, "Weight")}
                                                                secondary={props.ProductInfo.dimensions.weight + "kg"}
                                                            />
                                                        </ListItem>
                                                    </List>
                                                </AccordionDetails>
                                            </Accordion>
                                     )}
                                </div>
                    </form>
                </DialogContent>
                <DialogActions style={{ margin: "auto", padding: "20px", justifyContent: 'center'}}>
                    <Button
                        variant="outlined" onClick={async (event) => { HandleClose(setOpen) }}
                        style={{ color: "rgba(0, 0, 0, 0.54)" }}
                        endIcon={<KeyboardReturnIcon />}
                    >
                        {props.Languages.GetText(props.CatLang,"continueShopping")}
                        </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
