const item = `<ItemIn quantity="%s">  
                    <ItemID>
                        <SupplierPartID>%s</SupplierPartID>
                    </ItemID>
                    <ItemDetail>
                        <UnitPrice>
                            <Money currency="%s">%s</Money>
                        </UnitPrice>
                        <Description xml:lang="en">%s</Description>
                        <UnitOfMeasure>%s</UnitOfMeasure>
                        <Classification domain="UNSPSC">%s</Classification>
                        <ManufacturerPartID>%s</ManufacturerPartID>
                        <ManufacturerName>%s</ManufacturerName>
                        <LeadTime>%s</LeadTime>
                        <Extrinsic name="ProductImage">%s</Extrinsic>
                    </ItemDetail>
            </ItemIn>`;

export default item;