import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import { useApolloClient } from '@apollo/react-hooks';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Badge from '@material-ui/core/Badge';
import { BasketCard } from './BasketCard';
import IconButton from '@material-ui/core/IconButton';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DeleteIcon from '@material-ui/icons/Delete';
import { Carriage } from './Carriage';
import { EmptyBasket } from './EmptyBasket';

const CurrencySymbols = require('../../Data/CurrencySymbols.json');

const services = require('../../Services/Basket/Actions');
const BasketStyles = require("../../Services/Content/ComponentStyles").Basket();

const useStyles = BasketStyles;

export const Basket = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [IsBasketCountLoaded, SetBasketCountLoaded] = React.useState(false);
    const [BasketRows, setBasketRows] = React.useState([]);
    const client = useApolloClient();
    const [TotalPrice, setTotalPrice] = React.useState(0.00);
    const [TotalPriceWithoutCarriage, setTotalPriceWithoutCarriage] = React.useState(0.00);
    const [basketAddDisabled, setBasketAddDisabled] = React.useState(false);

    var basketCurrency = BasketRows.length == 0 ? "" : BasketRows[0].product.currency;
    var currencySymbol = typeof CurrencySymbols[basketCurrency] != "undefined" ? CurrencySymbols[basketCurrency].symbol : "";
    let finalCost = parseFloat(TotalPrice).toFixed(2)
    finalCost = new Intl.NumberFormat(props.CatLang.culture, { minimumFractionDigits: 2 }).format(finalCost)

    function onSubmit(formState) {
        setTotalPrice(formState[0].toFixed(2));
        setTotalPriceWithoutCarriage(formState[1].toFixed(2));
    }
    function RefreshBasket(formState) {
        setBasketRows(formState);
    }
    //debugger;
    if (!IsBasketCountLoaded)
        services.SetBasketCount(client, props.CatConfig.name, props.SessionId, props.SetBasketCount, SetBasketCountLoaded);
    //debugger;
    return (
        <div>
            <div className="zoom" onClick={async (event) => { services.OpenBasket(client, setOpen, setBasketRows, setTotalPrice, setTotalPriceWithoutCarriage, props.CatConfig.name, props.SessionId, props.CatConfig.carriageOptions) }}>
                <IconButton color="primary" style={{ padding: "0px" }} className={classes.iconButton} aria-label="directions">
                    <Badge id="bCounter" badgeContent={props.basketCount.toString()} color="error">
                        <ShoppingCartIcon style={{ color: 'white' }} />
                    </Badge>
                </IconButton>
                <p className={classes.iconText}>{props.Languages.GetText(props.CatLang, "ViewBasket")}</p>
            </div>
            <Dialog disableEscapeKeyDown open={open} onClose={async (event) => { services.HandleClose(setOpen, props.SetBasketCount) }}>
                <DialogTitle style={{ color: "rgba(0, 0, 0, 0.54)", fontWeight: "bold", paddingBottom: 0 }}>{props.Languages.GetText(props.CatLang, "BasketTotal")}: {currencySymbol}{finalCost}<input id="lblTotalCost" type="hidden" value={TotalPrice} /></DialogTitle>
                {BasketRows.length > 0 ? <Button onClick={async (event) => { services.ClearBasket(client, props.CatConfig.name, props.SessionId, RefreshBasket, setTotalPrice, props.SetBasketCount) }}
                    variant="outlined"
                    style={{ color: "rgba(0, 0, 0, 0.54)", width: "40%", margin: "auto", marginTop: "15px", marginBottom: "10px" }}
                    className={classes.button}
                    endIcon={<DeleteIcon />}
                >
                    {props.Languages.GetText(props.CatLang, "EmptyBasket")}
                </Button> : ""}
                
                {BasketRows.length > 0 ? <Carriage Languages={props.Languages} CatLang={props.CatLang} CarriageCharges={props.CatConfig.carriageOptions} TotalCost={TotalPriceWithoutCarriage} Currency={basketCurrency} Color={props.CatConfig.themeColour} /> : ""}

                <DialogContent>
                    <form className={classes.container}>
                        <FormControl className={classes.formControl}>
                            {
                                (BasketRows.length == 0) && (
                                 <EmptyBasket Languages={props.Languages} CatLang={props.CatLang} />
                            )}
                            {
                                BasketRows.map(item => {
                                    return <BasketCard Languages={props.Languages} CatLang={props.CatLang} SessionId={props.SessionId} CatConfig={props.CatConfig} className={classes.card} item={item} onSubmit={onSubmit} RefreshBasket={RefreshBasket} SetBasketCount={props.SetBasketCount} SetBasketAddDisabled={setBasketAddDisabled} />;
                                })
                            }

                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions style={{ margin: "auto", paddingBottom: "15px", paddingLeft: "15px", paddingRight: "15px", paddingTop: "0px" }}>

                    <Button onClick={async (event) => { props.SetIsProgressing([true, "ReturnBasket", props.Languages.GetText(props.CatLang, "ReturningyourbasketbacktoMyMRO"), props.CatConfig.carriageOptions]) }}
                        variant="contained"
                        disabled={basketAddDisabled}
                        style={{ backgroundColor: props.CatConfig.themeColour, color: "white" }}
                        className={classes.button}
                        endIcon={<ExitToAppIcon />}
                    >
                        {props.Languages.GetText(props.CatLang, "ReturnBasketToMyMRO")}
      </Button>

                    <Button onClick={async (event) => { services.HandleClose(setOpen) }}
                        variant="outlined"
                        style={{ color: "rgba(0, 0, 0, 0.54)" }}
                        className={classes.button}
                        endIcon={<KeyboardReturnIcon />}
                    >
                        {props.Languages.GetText(props.CatLang, "continueShopping")}
      </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
