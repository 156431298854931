import React, { useState, useEffect } from "react";
import { PageGenerator } from '../CardLayout/PageGenerator';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';

var AppStyle = {
    divStyle: {
        width: '100%',
        display: 'block',
        margin: 'auto'
    },
    footer: {
        width: '100%',
        fontSize: '12px',
        color: '#a1a1a1',
        fontWeight: 'bold',
        display: 'block',
        marginTop: '1%'
    }
}

export const ProductGrid = ({ CatLang, Languages, setSearchText, setResetSearch, SetFilteredFavourite, setClearFilter, ClearFilter, SearchProducts, CatConfig, classes, SessionId, isFilteredFavourite, basketCount, SetBasketCount, IsQueried, filters, SideFilter, SetSideFilter }) => {
    const client = useApolloClient();
    var CATALOGUE_ITEMS = gql`query Categories($cat: ID!){
                                      catalogue(name: $cat){
                                        categories
                                      }
                                    }`;

    const [categories, setCategories] = React.useState([]);

    useEffect(() => {
        client.query({ query: CATALOGUE_ITEMS, variables: { cat: CatConfig.name } }).then(data => { setCategories(data.data.catalogue.categories); }).catch(ex => { });
    }, CatConfig)

    return (
        <div>
            <div style={AppStyle.divStyle}>
                <PageGenerator classes={classes} SessionId={SessionId} CatConfig={CatConfig} isFilteredFavourite={isFilteredFavourite} basketCount={basketCount} SetBasketCount={SetBasketCount} filters={filters} IsQueried={IsQueried} ClearFilter={ClearFilter} setClearFilter={setClearFilter} categories={categories} CatLang={CatLang} Languages={Languages} SideFilter={SideFilter} SetSideFilter={SetSideFilter} />
            </div>
            <div style={AppStyle.footer}>{Languages.GetText(CatLang, "poweredBy")}</div>
        </div>
    )

    
};