import gql from 'graphql-tag';

const Audit = require('../Audit/Actions')

var Query = gql`query CatConfig($Catalogue:ID!) {
                  catalogue(name: $Catalogue) {
                      catalogueConfig { 
                           carriageOptions{
                                carriageTrigger
                                carriageCharge
                           }
                           language
                           imageRoute
                           supplierLogoUrl                        
      				       themeColour
                           resultsInfoColour
                           socialMedia{
                                facebook
                                instagram 
                                youtube
                                twitter
                                linkeden
                                pinterest
                            }
                        contactDetails{
                            department
                            email
                            phoneNumber
                        }
                      }                    
                  }
                }`;

export const SetCatalogueConfig = async (cat, catConfig, SetCatConfig, client, SetValidSession) => {
    if (catConfig != 0) return;
    if (catConfig == 0) {
        await client.query({
            query: Query, variables: { Catalogue: cat },
        }).then(result => {
           // debugger;
            if (result.data == null) {
                Audit.Add(client, "ERROR", result.error);
                SetValidSession(false);
                return;
            }     
            result.data.catalogue.catalogueConfig.name = cat;
            SetCatConfig(result.data.catalogue.catalogueConfig);   
        }).catch(ex => {
            Audit.Add(client, "ERROR", ex);
            SetValidSession(false);
        })
    }
}
