import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    FilterButtonVis: {
        margin: '20px 6px 20px 6px',
        padding: '2px 4px',
        alignItems: 'center',
    },
    FilterButtonHid: {
        display: "none"
    }
}));
export const FilterClear = ({ CatLang, Languages, SetFilteredFavourite, setClearFilter, CatConfig, SearchProducts, filters, SideFilter, DrawerOpen, onSubmit }) => {
    var classes = useStyles();
    var SearchfilterCount = (filters != null ? filters.length : 0)
    var SidefilterCount = SideFilter.length
    var disabled = (SearchfilterCount + SidefilterCount < 1);
    var displayOption = DrawerOpen ? "" : "none";
    var FilterText = Languages.GetText(CatLang, "ClearFilter")
    if (!Array.isArray(filters) && filters != null)
        SearchfilterCount = 1;
    return (
        <Button id="btnClearFilter" variant="contained" style={{ backgroundColor: CatConfig.themeColour, color: "white", display: displayOption }} disabled={disabled} className={classes.FilterButtonVis} onClick={async (event) => { SearchProducts(event, "", "btnClearFilter"); setClearFilter(true); SetFilteredFavourite(false); }
        }>
            {FilterText} ({SearchfilterCount + SidefilterCount})
    </Button>);
}