import React from 'react';
import EmptyShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';

export const EmptyBasket = (props) => {
    return (<div style={{ margin: "auto", textAlign: "center" }}>
                <div style={{ fontSize: "1em", color: "rgba(0, 0, 0, 0.54)", padding: "0px" }}>
            {props.Languages.GetText(props.CatLang, "YourBasketisEmpty")}
                </div>
        <EmptyShoppingCartIcon style={{ fontSize: "6em", color: "rgba(0, 0, 0, 0.54)", paddingBottom: "15px"}} />
            </div>)
}