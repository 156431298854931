import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/react-hooks';
import React from 'react';

const Audit = require('../Audit/Actions');
const FilterServices = require('../../Services/Filters/Actions');
export const GetCatalogueItems = (params, data) => {
    debugger;
    const client = useApolloClient();
    var gQL = gql` 
                query local($Products:[String!])
                {
                  locale {
                    areaCode
                    catalogues {
                      name
                      products(productCode:$Products){
                        productCode
                        description
                        longDescription
                        image
                        manufName
                        manufCode
                        price
                        currency
                        minOrderQty
                        orderQty
                      }
                    }
                  }
                }
                `;
    return client.query({
        query: gQL, variables: params,
    });

}
export default function GetCatalogueData() {
    const query = gql`query catalogue($cat: ID!,$limit: Int, $searchTerm: [String] $filterTerm: [FilterClause!], $token: String!) {
                                  catalogue(name: $cat) {
                                    name
                                    products(limit: $limit, searchTerm: $searchTerm, filter: $filterTerm, token: $token) {
                                      items {
                                        productCode
                                        description
                                        longDescription
                                        price
                                        currency
                                        per
                                        uom
                                        orderQty
                                        roundingQty
                                        minOrderQty
                                        leadtime
                                        UNSPSC
                                        manufName
                                        manufCode
                                        image
                                        match
                                      }
                                     token
                                    }
                                  }
                                }`
    return query
}
function ConvertArrayToLowercase(obj) {
    var result = [];
    for (var i = 0; i <= obj.length - 1; i++)
        result.push(obj[i]);
    return result;
}
export const SearchProducts = (cat, apolloclient, e, property, id, props, SetFilter, categories, setCategories, Manfs, SetManfs, SetSearchBar, SetFavouriteIsSelected, SetQueried, SideFilter, SetSideFilter, setClearFilter) => {
    var chips = ["Categories", "manufName"];
    var SearchButtons = ["SearchBar", "btnClearFilter"];
    var FilterTerms = [];
    var SelectedFilters = [];
    var SelectedUNSPSCFilters = [];
    var SelectedManufFilters = [];
    if (SearchButtons.includes(id)) {
        if (property != "") {
            SetFilter(props = property.split(','));
            SetSearchBar(property);
        } else {
            SetFilter(null);
            setCategories([]);
            SetManfs([]);
        }
    }
    if (chips.includes(id)) {
        for (var i = 0; i < property.length; i++) {
            if (id == "Categories") {
                setCategories(categories = ConvertArrayToLowercase(property));
                SelectedUNSPSCFilters.push(property[i][0])
            }
            if (id == "manufName") {
                SetManfs(Manfs = ConvertArrayToLowercase(property));
                SelectedManufFilters.push(property[i])
            }
        }
            if (id == "Categories") {
                for (var i = 0; i < SideFilter.length; i++) {
                    if (SideFilter[i].attribute == "UNSPSC") {
                        SetSideFilter(SideFilter.splice(i, 1));
                    }
                }
            }
            if (id == "manufName") {
                for (var i = 0; i < SideFilter.length; i++) {
                    if (SideFilter[i].attribute == "manufName") {
                        SetSideFilter(SideFilter.splice(i, 1));
                    }
                }
            }

        if (property.length == 0) {
            if (id == "Categories") {
                setCategories(categories = []);
            }

            if (id == "manufName") {
                SetManfs(Manfs = []);
            }
        }

        FilterTerms = FilterTerms.concat(categories);
        FilterTerms = FilterTerms.concat(Manfs);
        if (SelectedUNSPSCFilters.length > 0) SelectedFilters.push({ attribute: "UNSPSC", filterTerm: SelectedUNSPSCFilters })
        if (SelectedManufFilters.length > 0) SelectedFilters.push({ attribute: "manufName", filterTerm: SelectedManufFilters })

    }


    if (id == "ShowFavourites") {
        var favourites = FilterServices.GetFavourites();
        SetFavouriteIsSelected(true);
        if (favourites.length > 0) SelectedFilters.push({ attribute: "productCode", filterTerm: favourites })
    }

    if (id == "HideFavourites") {
        for (var i = 0; i < SideFilter.length; i++) {
            if (SideFilter[i].attribute == "productCode") {
                SetSideFilter(SideFilter.splice(i, 1));
            }
        }
        SetFavouriteIsSelected(false);
    }


    SetSideFilter(SideFilter.concat(SelectedFilters))

    SetQueried(false);
    if (id == "btnClearFilter" || (SetSideFilter.length == 0 && props == null)) {
        SetSideFilter([])
        SetFilter(null)
        setClearFilter(true)
    } 
}

export const GetFavourites = () => {
    //debugger;
    var favourites = localStorage.getItem("SupplyCatFavourites");
    if (favourites == null) return "NaN";
    return JSON.parse(favourites).Favourites
}
