import React from 'react';
import ReactDOM from 'react-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import PeopleIcon from '@material-ui/icons/People';
import { ContactsCard } from './ContactsCard';
import IconButton from '@material-ui/core/IconButton';

const services = require('../../Services/Basket/Actions');
const BasketStyles = require("../../Services/Content/ComponentStyles").Basket();

const useStyles = BasketStyles;

export const Contacts = (props) => {
    //debugger;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    return (
        <div>
            <div className="zoom" onClick={async (event) => { setOpen(true); }}>
                <IconButton color="primary" style={{ padding: "0px" }} aria-label="directions">
                    <PeopleIcon style={{ color: 'white' }} />
                </IconButton>
                <p className={classes.iconText}>{props.Languages.GetText(props.CatLang, "ContactUs")}</p>
            </div>
            <Dialog disableEscapeKeyDown open={open} onClose={async (event) => { services.HandleClose(setOpen) }}>
                <DialogTitle style={{ color: "rgba(0, 0, 0, 0.54)", fontWeight: "bold", paddingBottom: 0 }}>{props.Languages.GetText(props.CatLang, "ContactDetails")}</DialogTitle>
                <DialogContent>
                    <form className={classes.container}>
                        <FormControl className={classes.formControl}>
                            {
                                
                                props.CatConfig.contactDetails.map((item, i) => {
                                    return <ContactsCard SupplierLogo={props.SupplierLogo} key={i} item={item} CatLang={props.CatLang} Languages={props.Languages} />;
                                })
                            }

                        </FormControl>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}
