import { fade, makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

export const ExtractMainStyles = (themeColour) => {
    return makeStyles(theme => ({
        margin: {
            margin: theme.spacing(1),
        },
        '.MuiButton-outlined': {
            color: "red"
        },
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        formControl: {
            minWidth: 500
        },
        grow: {
            flexGrow: 1
        },
        MoreproductInfo: {
            display: 'block',
            alignItems: 'center',
            width: '100%',
            margin: 'auto',
        },
        SwitchAvatar: {
            paddingTop: '30px',
            display: 'block',
            alignItems: 'center',
            width: '100%',
            margin: 'auto',
            paddingBottom: '5px'
        },
        menuButton: {
            marginLeft: theme.spacing(2),
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',

            },
        },
        iconButton:{
            flexDirection: "column",
            display:"flex"
        },
        iconText: {
            marginBlockStart: "auto",
            marginBottom: "0",
            fontSize: "smaller",
            marginTop: "0px"
        },
        descriptionText: {
            fontSize: "medium",
            color: "rgba(0, 0, 0, 0.54)",
            minHeight: "70px",
            fontWeight: "bold",
            verticalAlign: "middle"
        },

        descriptionTextnmt: {
            fontSize: "medium",
            color: "rgba(0, 0, 0, 0.54)",
            minHeight: "70px",
            fontWeight: "bold",
            verticalAlign: "middle",
            paddingbottom: 0,
        },

        descriptionTextExactMatch: {
            fontSize: "medium",
            color: "#ff8c1c",
            minHeight: "10px",
            paddingTop: 10,
            PaddingBottom: 0,
            fontWeight: "bold",
            verticalAlign: "middle"
        },

        descriptionTextBestMatch: {
            fontSize: "medium",
            color: "#C5C5C5",
            minHeight: "10px",
            paddingTop: 10,
            PaddingBottom: 0,
            fontWeight: "bold",
            verticalAlign: "middle"
        },

        descriptionTextNoMatch: {
            fontSize: "medium",
            padding: "0px",
            fontWeight: "bold",
            verticalAlign: "middle"
        },

        supplierTitle: {
            letterSpacing: "2px",
            marginLeft:"7px"
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginLeft: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',

            },
            img: {
                marginTop: theme.spacing(100),
            },
        },
        root: {
            display: 'flex',
            boxShadow: '0px 2px 4px - 1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 30,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            zIndex: theme.zIndex.drawer + 30,
            marginLeft: 300,
            width: `calc(100%)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: 300,
            flexShrink: 0,
            whiteSpace: 'nowrap'

        },
        drawerOpen: {
            overflowY: "inherit",
            paddingTop: "60px",
            width: 300,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.standard,
            }),
        },
        drawerClose: {
            paddingTop: "60px",
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            // overflowX: 'hidden',
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        filterText: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        toolbar: {
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            marginTop: "2%"
        },
        filters: {
            marginTop: "15px",
            overflowX: "hidden",
            overflowY:"hidden"
        },
        FilterText: {
            writingMode: "vertical-lr",
            textOrientation: "sideways",
            letterSpacing:"8px",
            fontWeight: "bold",
            marginTop: "50%",
            marginBottom:"30%",
            color:"rgba(0, 0, 0, 0.54)"
        },
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 30,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            zIndex: theme.zIndex.drawer + 30,
            marginLeft: 300,
            width: `calc(100%)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        toolTip: {
            fontSize: "13px"
        },
        content: {
            flexGrow: 7,
            overflow: "hidden",
            marginTop: "63px"

        },
        supplierLogo: {
            width: "40px",
            height: "40px",
            margin: "6px",
            padding: "4px",
            backgroundColor: "#f8f8f8",
            border: "solid 1px #ced4da !important",
            borderRadius: ".2rem"
        },
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(6),
            marginRight: theme.spacing(1),
            width: "20%",
            '& label.Mui-focused': {
                color: themeColour
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: themeColour
            },
        },

        title: {
            marginTop: "4%",
            marginBottom: "4%"
        },
        card: {
            maxWidth: "230px",
            minHeight: "388px",
            minWidth: "230px",
        },
        cardExact: {
            maxWidth: "230px",
            minHeight: "388px",
            minWidth: "230px",
            border: `1px solid #ff8c1c`
        },
        cardBest: {
            maxWidth: "230px",
            minHeight: "388px",
            minWidth: "230px",
            border: `1px solid #C5C5C5`
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        avatar: {
            backgroundColor: red[0],
        },
        expandTitle: {
            fontWeight: "bold",
            color: "#1C9576",
            fontSize: "inherit",
            paddingBottom: "1px"
        },
        expandParagraph: {
            fontSize: "medium"
        },
        descriptionText: {
            fontSize: "16px",
            color: "rgba(0, 0, 0, 0.54)",
            minHeight: "70px",
            maxHeight: "70px",
            fontWeight: "bold",
            verticalAlign: "middle",
            textOverflow:"elipses",
        },
        img: {
            margin: 'auto',
            display: 'block',
            width: "150px",
            height: "130px",
            cursor: "pointer"
        },
    }))
}

export const Basket = () => {
    return makeStyles(theme => ({
        margin: {
            margin: theme.spacing(1),
        },
        '.MuiButton-outlined': {
            color: "red"
        },
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        formControl: {
            minWidth: 500
        },
        iconButton: {
            flexDirection: "column",
        },
        iconText: {
            marginBlockStart: "auto",
            marginBottom:"0",
            fontSize: "smaller",
            marginTop: "0px"
        },
    }));
}