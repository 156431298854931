import React, { useEffect } from "react";
import { amber, green } from '@material-ui/core/colors';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles } from '@material-ui/core/styles';
import {
    Snackbar,
    SnackbarContent,
    IconButton,
    CheckCircleIcon,
    CloseIcon,
    InfoWrapper
} from "./Style";

const useStyles1 = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
}));

function SimpleSnackbar({ setStatusBase, status, msg,type }) {
    const classes = useStyles1();
    const [open, setOpen] = React.useState(true);
    useEffect(
        () => {
            setOpen(true);

        },
        [status]
    );

    function handleClose(event, reason) {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        setStatusBase("");
    }
    var alertClass;
   // debugger;
    if (type == "success")
        alertClass = classes.success;
    else
        alertClass = classes.error;
    return (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
        >
            <SnackbarContent
                className={alertClass}
                status={status}
                variant={type}
                contentprops={{
                    "aria-describedby": "message-id"
                }}
                // prettier-ignore
                message={(
                    <InfoWrapper id='message-id'>
                        {(type == "success") ? <CheckCircleIcon /> : <ErrorIcon />}
                        {msg || `${status}`}
                    </InfoWrapper>
                )}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            />
        </Snackbar>
    );
}
export default SimpleSnackbar;
