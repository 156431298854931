import React from 'react';
import Typography from '@material-ui/core/Typography';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

const Services = require('../../Services/Basket/Actions');
const CurrencySymbols = require('../../Data/CurrencySymbols.json');

export const Carriage = ({ Languages, CatLang, CarriageCharges, TotalCost, Currency,Color }) => {
    //debugger;
    var curr = CurrencySymbols[Currency];
    var carriageCost = Services.CalculateCarriage(CarriageCharges, TotalCost);
    var carriageMessage;
    //debugger;
    if (carriageCost == null)
        return "";
    if (carriageCost == 0) carriageMessage = Languages.GetText(CatLang, "deliveryFreeOfCharge");
    else
        carriageMessage = curr.symbol + carriageCost.toFixed(2) + " " + Languages.GetText(CatLang, "deliveryChargeHasBeenApplied");
    return (
        <div style={{ color: Color, fontWeight: "bold", paddingBottom: 0, justifyContent: "center", margin:"0px auto 5px",display:"flex" }}>
            <LocalShippingIcon style={{marginRight:"4px"}} />  <Typography>{carriageMessage}</Typography>
        </div>
    )
};